import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<div class=\"h-100\" {{this.dataModifier @data setup=this.setup update=this.update}}>\n</div>", {"contents":"<div class=\"h-100\" {{this.dataModifier @data setup=this.setup update=this.update}}>\n</div>","moduleName":"cryptofiscafacile-gui/components/ac5/column/index.hbs","parseOptions":{"srcName":"cryptofiscafacile-gui/components/ac5/column/index.hbs"}});
import { action } from '@ember/object';

import { color, p50, p100, Tooltip } from '@amcharts/amcharts5';
import { AxisRendererX } from '@amcharts/amcharts5/.internal/charts/xy/axes/AxisRendererX';
import { AxisRendererY } from '@amcharts/amcharts5/.internal/charts/xy/axes/AxisRendererY';
import { CategoryAxis } from '@amcharts/amcharts5/.internal/charts/xy/axes/CategoryAxis';
import { ValueAxis } from '@amcharts/amcharts5/.internal/charts/xy/axes/ValueAxis';
import { ColumnSeries } from '@amcharts/amcharts5/.internal/charts/xy/series/ColumnSeries';
import { XYChart } from '@amcharts/amcharts5/.internal/charts/xy/XYChart';

import { Ac5chart } from '../chart';

import type { Color, DataItem, RoundedRectangle } from '@amcharts/amcharts5';
import type { AxisRenderer } from '@amcharts/amcharts5/.internal/charts/xy/axes/AxisRenderer';
import type { IColumnSeriesDataItem } from '@amcharts/amcharts5/.internal/charts/xy/series/ColumnSeries';

interface Ac5ColumnArgs {
  categoryField: string;
  categoryFieldMapper: (category: string) => string;
  data: { [x: string]: number | string }[];
  valueField: string;
}

export default class Ac5Column extends Ac5chart<Ac5ColumnArgs> {
  chart?: XYChart;
  series?: ColumnSeries;
  xAxis?: CategoryAxis<AxisRenderer>;

  @action
  setup(element: HTMLElement) {
    super.setupChart(element);
  }

  protected configure() {
    if (!this.root) {
      return;
    }

    this.chart = this.root.container.children.push(
      XYChart.new(this.root, {
        panX: true,
        panY: true,
        wheelX: 'panX',
        wheelY: 'zoomX',
      }),
    );

    // Create axes
    // https://www.amcharts.com/docs/v5/charts/xy-chart/axes/
    const xRenderer = AxisRendererX.new(this.root, { minGridDistance: 30 });

    xRenderer.labels.template.setAll({
      rotation: -90,
      centerY: p50,
      centerX: p100,
      paddingRight: 15,
    });

    this.xAxis = this.chart.xAxes.push(
      CategoryAxis.new(this.root, {
        maxDeviation: 0.3,
        categoryField: 'country',
        renderer: xRenderer,
        tooltip: Tooltip.new(this.root, {}),
      }),
    );

    const yAxis = this.chart.yAxes.push(
      ValueAxis.new(this.root, {
        maxDeviation: 0.3,
        renderer: AxisRendererY.new(this.root, {}),
      }),
    );

    // Create series
    // https://www.amcharts.com/docs/v5/charts/xy-chart/series/
    this.series = this.chart.series.push(
      ColumnSeries.new(this.root, {
        name: 'Visits',
        xAxis: this.xAxis,
        yAxis: yAxis,
        valueYField: this.args.valueField,
        sequencedInterpolation: true,
        categoryXField: 'country',
        tooltip: Tooltip.new(this.root, {
          labelText: '{valueY}',
        }),
      }),
    );

    this.series.columns.template.adapters.add('fill', this.#valueYcolor);
    this.series.columns.template.adapters.add('stroke', this.#valueYcolor);

    this.update();
  }

  #valueYcolor = (colorValue: Color | undefined, target: RoundedRectangle) => {
    if (target) {
      const dataItem = target.dataItem as DataItem<IColumnSeriesDataItem>;

      const valueY = dataItem.getRaw('valueY');

      return valueY && valueY > 0 ? color('#439a86') : color('#bb4430');
    }

    return colorValue;
  };

  @action
  update() {
    if (this.chart) {
      const data = this.args.data?.filter((slice) => slice[this.args.valueField]);

      this.xAxis?.data.setAll(data);
      this.series?.data.setAll(data);

      this.series?.appear(1000);
      this.chart.appear(1000, 100);
    }
  }
}
