import Controller from '@ember/controller';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';

import { tracked } from 'tracked-built-ins';

import type ApplicationService from 'cryptofiscafacile-gui/services/application';
import type WasmService from 'cryptofiscafacile-gui/services/wasm';

export default class ApplicationController extends Controller {
  @service declare application: ApplicationService;
  @service declare wasm: WasmService;

  @tracked _newVersionModalVisible = false;

  get newVersionModalVisible() {
    return this._newVersionModalVisible || this.application.newVersion;
  }

  @action
  onNewVersionModalHidden() {
    this._newVersionModalVisible = false;

    this.wasm.updateApplicationEntryTask.perform({
      key: 'application.version',
      value: this.application.version,
    });
    this.application.newVersion = false;
  }
}

// DO NOT DELETE: this is how TypeScript knows how to look up your controllers.
declare module '@ember/controller' {
  interface Registry {
    'application-controller': ApplicationController;
  }
}
