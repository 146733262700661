import Modifier from 'ember-modifier';

import type { Root } from '@amcharts/amcharts5';
import type { Owner } from '@ember/test-helpers/build-owner';
import type { ArgsFor, NamedArgs } from 'ember-modifier';

interface Ac5ChartDataModifierSignature {
  Element: Element;
  Args: {
    Positional: [data: unknown];
    Named: {
      setup(element: HTMLElement): Root;
      update: () => void;
    };
  };
}

export default class Ac5ChartDataModifier extends Modifier<Ac5ChartDataModifierSignature> {
  didSetup = false;

  constructor(owner: Owner, args: ArgsFor<Ac5ChartDataModifierSignature>) {
    super(owner, args);

    if (!args.named.setup) {
      throw new Error('setup must be defined');
    }

    if (typeof args.named.setup !== 'function') {
      throw new Error('setup must be a function');
    }

    if (!args.named.update) {
      throw new Error('update must be defined');
    }

    if (typeof args.named.update !== 'function') {
      throw new Error('update must be a function');
    }
  }

  modify(element: HTMLElement, __: [data: unknown], args: NamedArgs<Ac5ChartDataModifierSignature>) {
    if (!this.didSetup) {
      // expensive setup
      args.setup(element);
      this.didSetup = true;

      return;
    }

    args.update();
  }
}
