import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<BsButton\n  data-bs-dismiss=\"offcanvas\"\n  data-bs-target=\"#offcanvasFilter\"\n  aria-label=\"Close\"\n  @type=\"light\"\n  @onClick={{this.unMerge}}\n  class=\"d-flex justify-content-evenly align-items-center align-content-center custom-filter-flex p-0 pe-1 pb-1 bg-white\"\n>\n  <span class=\"categ-icon\">\n    <App::Icon @icon=\"ion:arrow-undo\" width=\"20\" />\n    <BsTooltip @placement=\"top\">Transaction à dé-fusionner</BsTooltip>\n  </span>\n</BsButton>", {"contents":"<BsButton\n  data-bs-dismiss=\"offcanvas\"\n  data-bs-target=\"#offcanvasFilter\"\n  aria-label=\"Close\"\n  @type=\"light\"\n  @onClick={{this.unMerge}}\n  class=\"d-flex justify-content-evenly align-items-center align-content-center custom-filter-flex p-0 pe-1 pb-1 bg-white\"\n>\n  <span class=\"categ-icon\">\n    <App::Icon @icon=\"ion:arrow-undo\" width=\"20\" />\n    <BsTooltip @placement=\"top\">Transaction à dé-fusionner</BsTooltip>\n  </span>\n</BsButton>","moduleName":"cryptofiscafacile-gui/components/tx-table/tx-item/unmerged.hbs","parseOptions":{"srcName":"cryptofiscafacile-gui/components/tx-table/tx-item/unmerged.hbs"}});
import Component from '@glimmer/component';
import { debug } from '@ember/debug';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';

import type WasmService from 'cryptofiscafacile-gui/services/wasm';

interface TxTableTxItemUnmergedArgs {
  id: string;
}

export default class TxTableTxItemUnmerged extends Component<TxTableTxItemUnmergedArgs> {
  @service declare wasm: WasmService;

  @action
  async unMerge() {
    debug('Unmerge transactions with id : ' + this.args.id);
    await this.wasm.unMergeTransactionsTask.perform(this.args.id);
    await this.wasm.getTransactionsTask.perform();
  }
}
