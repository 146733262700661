import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';

import type ApplicationService from 'cryptofiscafacile-gui/services/application';
import type WasmService from 'cryptofiscafacile-gui/services/wasm';

export default class IndexRoute extends Route {
  @service declare application: ApplicationService;
  @service declare wasm: WasmService;

  beforeModel() {
    this.wasm.getSynthBalancesTask.perform(this.wasm.defaultEndDate);
    this.wasm.getSynthStatTotalValueTask.perform(this.wasm.defaultEndDate);
    this.wasm.getSynthStatsGainsLossesTask.perform(this.wasm.defaultEndDate);
    this.wasm.getSynthPortfolioTask.perform(this.wasm.defaultEndDate);
    this.wasm.getSynthEvolutionTask.perform(this.wasm.defaultStartDate, this.wasm.defaultEndDate);
    this.wasm.getSynthTxTypesTask.perform(this.wasm.defaultStartDate, this.wasm.defaultEndDate);
    this.wasm.getWalletsTask.perform();
  }

  activate() {
    this.application.helpTarget = '#intro-synth';
  }
}
