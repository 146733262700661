import Modifier from 'ember-modifier';

import type { PositionalArgs } from 'ember-modifier';

interface DefaultPageSizeModifierSignature {
  Args: {
    Positional: [(pageSize: number) => void];
  };
}

export default class PageSizeModifierModifier extends Modifier<DefaultPageSizeModifierSignature> {
  didSetup = false;

  modify(element: Element, [setPageSize]: PositionalArgs<DefaultPageSizeModifierSignature>) {
    if (!this.didSetup) {
      if (element.clientWidth >= 992 && element.clientWidth < 1920) {
        setPageSize(10);
      } else if (element.clientWidth >= 1920 && element.clientWidth < 2560) {
        setPageSize(20);
      } else if (element.clientWidth >= 2560 && element.clientWidth < 3840) {
        setPageSize(30);
      } else if (element.clientWidth >= 3840) {
        setPageSize(40);
      }

      this.didSetup = true;
    }
  }
}
