import { TxCategory } from 'cryptofiscafacile-wasm';

import type { Transaction } from 'cryptofiscafacile-wasm';

export default class Transactions {
  private constructor() {
    // Utility class
  }

  static isUserCategorizable(tx: Transaction) {
    return (
      tx.category === TxCategory.DEPOSITS ||
      tx.category === TxCategory.WITHDRAWALS ||
      tx.original_category === TxCategory.DEPOSITS ||
      tx.original_category === TxCategory.WITHDRAWALS
    );
  }
}
