import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';

import type ApplicationService from 'cryptofiscafacile-gui/services/application';
import type WasmService from 'cryptofiscafacile-gui/services/wasm';
export default class TransactionsRoute extends Route {
  @service declare application: ApplicationService;
  @service declare wasm: WasmService;

  model() {
    this.wasm.getTransactionsTask.perform();
  }

  activate() {
    this.application.helpTarget = '#intro-tx';
  }
}
