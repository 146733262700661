import { later } from '@ember/runloop';

import { modifier } from 'ember-modifier';

export default modifier((element: HTMLElement, positional: [string] /* named*/) => {
  const hide = () => {
    const id = positional[0];
    const offcanvas = document.getElementById(id);

    if (offcanvas) {
      offcanvas.classList.add('hiding');
      later(() => {
        if (offcanvas) {
          offcanvas.classList.remove('hiding');
          offcanvas.classList.remove('show');
        }
      }, 250);
    }
  };

  element.addEventListener('click', hide);

  return () => {
    element.removeEventListener('click', hide);
  };
});
