import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';

import type ApplicationService from 'cryptofiscafacile-gui/services/application';

export default class DocumentationRoute extends Route {
  @service declare application: ApplicationService;

  activate() {
    this.application.helpTarget = '#intro-synth';
  }
}
