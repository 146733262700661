import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<div class=\"input-group mb-3 w-50\">\n  {{! template-lint-disable require-input-label }}\n  <input\n    type=\"number\"\n    id=\"modifyFiat\"\n    name=\"modifyFiat\"\n    class=\"form-control ps-2 {{@invalid}}\"\n    step=\"0.01\"\n    placeholder={{format-number @value.quantity maximumFractionDigits=\"2\"}}\n    aria-label=\"Amount (to the nearest)\"\n    {{on \"change\" this.updateValue}}\n  />\n  <span class=\"input-group-text\">\n    {{@value.currency}}\n  </span>\n</div>", {"contents":"<div class=\"input-group mb-3 w-50\">\n  {{! template-lint-disable require-input-label }}\n  <input\n    type=\"number\"\n    id=\"modifyFiat\"\n    name=\"modifyFiat\"\n    class=\"form-control ps-2 {{@invalid}}\"\n    step=\"0.01\"\n    placeholder={{format-number @value.quantity maximumFractionDigits=\"2\"}}\n    aria-label=\"Amount (to the nearest)\"\n    {{on \"change\" this.updateValue}}\n  />\n  <span class=\"input-group-text\">\n    {{@value.currency}}\n  </span>\n</div>","moduleName":"cryptofiscafacile-gui/components/tx-table/tx-item/change-fiat/input-fiat.hbs","parseOptions":{"srcName":"cryptofiscafacile-gui/components/tx-table/tx-item/change-fiat/input-fiat.hbs"}});
import Component from '@glimmer/component';
import { action } from '@ember/object';

import type { TxValue } from 'cryptofiscafacile-wasm';

interface TxTableTxItemChangeFiatInputFiatArgs {
  value: TxValue;
  onChange: (values: number, old: number) => void;
}

export default class TxTableTxItemChangeFiatInputFiat extends Component<TxTableTxItemChangeFiatInputFiatArgs> {
  @action
  updateValue(e: { target: HTMLInputElement }) {
    if (e.target.value) {
      this.args.onChange(Number(e.target.value), this.args.value.quantity);
    } else {
      this.args.onChange(0, 0);
    }
  }
}
