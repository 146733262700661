import Modifier from 'ember-modifier';

import type { NamedArgs } from 'ember-modifier';

interface DownloadModifierSignature {
  Args: {
    Named: {
      filename: string;
      fileUrl: string;
    };
    Positional: [];
  };
}

export default class DownloadModifier extends Modifier<DownloadModifierSignature> {
  modify(_: Element, __: [], args: NamedArgs<DownloadModifierSignature>) {
    if (args.filename && args.fileUrl) {
      let downloadAnchorNode = document.createElement('a');

      downloadAnchorNode.setAttribute('href', args.fileUrl);
      downloadAnchorNode.setAttribute('download', args.filename);
      document.body.appendChild(downloadAnchorNode); // required for firefox
      downloadAnchorNode.click();
      downloadAnchorNode.remove();
    }
  }
}
