import { later } from '@ember/runloop';

import { modifier } from 'ember-modifier';
import introJs from 'intro.js';

export default modifier(
  (element, [target]: [string], { autoStart, onExit }: { autoStart?: boolean; onExit?: VoidFunction }) => {
    const startIntro = () =>
      introJs(target)
        .setOption('nextLabel', 'Suivant')
        .setOption('prevLabel', 'Retour')
        .setOption('doneLabel', 'Quitter')
        .setOption('tooltipClass', 'customDefault')
        .onexit(() => {
          if (onExit) {
            onExit();
          }
        })
        .start();

    element.addEventListener('click', startIntro);

    if (autoStart) {
      later(startIntro, 1000);
    }

    return () => {
      element.removeEventListener('click', startIntro);
    };
  },
);
