import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<div class=\"mx-2 my-3 {{@id}}\">\n  <label for={{@id}} class=\"form-label\">{{@label}}</label>\n  <div class=\"d-flex\">\n    {{yield}}\n    <CopyButton\n      class=\"btn btn-primary color\"\n      @target=\"#{{@id}}\"\n      @onSuccess={{this.onSuccess}}\n      @onError={{this.onError}}\n    >\n      <App::Icon @icon=\"ion:clipboard-outline\" />\n      <BsTooltip @placement=\"right\">Copier texte</BsTooltip>\n    </CopyButton>\n  </div>\n</div>", {"contents":"<div class=\"mx-2 my-3 {{@id}}\">\n  <label for={{@id}} class=\"form-label\">{{@label}}</label>\n  <div class=\"d-flex\">\n    {{yield}}\n    <CopyButton\n      class=\"btn btn-primary color\"\n      @target=\"#{{@id}}\"\n      @onSuccess={{this.onSuccess}}\n      @onError={{this.onError}}\n    >\n      <App::Icon @icon=\"ion:clipboard-outline\" />\n      <BsTooltip @placement=\"right\">Copier texte</BsTooltip>\n    </CopyButton>\n  </div>\n</div>","moduleName":"cryptofiscafacile-gui/components/user-wallets/transmit/content.hbs","parseOptions":{"srcName":"cryptofiscafacile-gui/components/user-wallets/transmit/content.hbs"}});
import Component from '@glimmer/component';
import { debug } from '@ember/debug';
import { action } from '@ember/object';

interface UserWalletsTransmitContentArgs {
  id: string;
  label: string;
}

export default class UserWalletsTransmitContent extends Component<UserWalletsTransmitContentArgs> {
  @action
  onSuccess() {
    debug('Copy to clipboard success');
  }
  @action
  onError() {
    debug('Copy to clipboard error... Please try again');
  }
}

// DO NOT DELETE: this is how TypeScript knows how to look up your controllers.
declare module '@glimmer/component' {
  interface Registry {
    'user-wallets-transmit-content': UserWalletsTransmitContent;
  }
}
