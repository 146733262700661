import { later } from '@ember/runloop';

import { modifier } from 'ember-modifier';

export default modifier((element: HTMLElement, positional: [string] /* named*/) => {
  const toggle = () => {
    const id = positional[0];
    const offcanvas = document.getElementById(id);

    if (offcanvas) {
      if (offcanvas.classList.contains('show')) {
        offcanvas.classList.add('hiding');
        later(() => {
          if (offcanvas) {
            offcanvas.classList.remove('hiding');
            offcanvas.classList.remove('show');
          }
        }, 250);
      } else {
        offcanvas.classList.add('show');
        offcanvas.classList.add('showing');
        later(() => {
          if (offcanvas) {
            offcanvas.classList.remove('showing');
          }
        }, 250);
      }
    }
  };

  element.addEventListener('click', toggle);

  return () => {
    element.removeEventListener('click', toggle);
  };
});
