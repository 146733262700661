import flatColorIconsBearish from '@iconify/icons-flat-color-icons/bearish';
import flatColorIconsBullish from '@iconify/icons-flat-color-icons/bullish';
import flatColorIconsCandleSticks from '@iconify/icons-flat-color-icons/candle-sticks';
import flatColorIconsCurrencyExchange from '@iconify/icons-flat-color-icons/currency-exchange';
import flatColorIconsElectroDevices from '@iconify/icons-flat-color-icons/electro-devices';
import flatColorIconsExport from '@iconify/icons-flat-color-icons/export';
import flatColorIconsInternal from '@iconify/icons-flat-color-icons/internal';
import ionAlertCircle from '@iconify-icons/ion/alert-circle';
import ionArrowUndo from '@iconify-icons/ion/arrow-undo';
import ionBarChartOutline from '@iconify-icons/ion/bar-chart-outline';
import ionBuild from '@iconify-icons/ion/build';
import ionCalculator from '@iconify-icons/ion/calculator';
import ionCalendarOutline from '@iconify-icons/ion/calendar-outline';
import ionChevronDown from '@iconify-icons/ion/chevron-down';
import ionChevronDownCircleOutline from '@iconify-icons/ion/chevron-down-circle-outline';
import ionChevronUpCircleOutline from '@iconify-icons/ion/chevron-up-circle-outline';
import ionClipboardOutline from '@iconify-icons/ion/clipboard-outline';
import ionDownloadOutline from '@iconify-icons/ion/download-outline';
import ionExitOutline from '@iconify-icons/ion/exit-outline';
import ionEyeOffOutline from '@iconify-icons/ion/eye-off-outline';
import ionEyeOutline from '@iconify-icons/ion/eye-outline';
import ionFlowerSharp from '@iconify-icons/ion/flower-sharp';
import ionFunnel from '@iconify-icons/ion/funnel';
import ionGiftOutline from '@iconify-icons/ion/gift-outline';
import ionHammerOutline from '@iconify-icons/ion/hammer-outline';
import ionHelpCircle from '@iconify-icons/ion/help-circle';
import ionHelpSharp from '@iconify-icons/ion/help-sharp';
import ionInformationCircleOutline from '@iconify-icons/ion/information-circle-outline';
import ionLibraryOutline from '@iconify-icons/ion/library-outline';
import ionListOutline from '@iconify-icons/ion/list-outline';
import ionPlayCircleOutline from '@iconify-icons/ion/play-circle-outline';
import ionPlayForwardCircleOutline from '@iconify-icons/ion/play-forward-circle-outline';
import ionRemoveOutline from '@iconify-icons/ion/remove-outline';
import ionSearch from '@iconify-icons/ion/search';
import ionShieldCheckmark from '@iconify-icons/ion/shield-checkmark';
import ionSparklesOutline from '@iconify-icons/ion/sparkles-outline';
import ionTrashSharp from '@iconify-icons/ion/trash-sharp';
import ionWalletOutline from '@iconify-icons/ion/wallet-outline';
import mdiEngineOffOutline from '@iconify-icons/mdi/engine-off-outline';
import mdiEngineOutline from '@iconify-icons/mdi/engine-outline';
import { addAPIProvider, addIcon, disableCache } from 'iconify-icon';

import type { IconifyIcon } from 'iconify-icon';

// Do not forget to remove unused icons to reduce build assets size
export const icons = new Map<string, IconifyIcon>()
  .set('cff:flat-color-icons:bearish', flatColorIconsBearish)
  .set('cff:flat-color-icons:bullish', flatColorIconsBullish)
  .set('cff:flat-color-icons:candle-sticks', flatColorIconsCandleSticks)
  .set('cff:flat-color-icons:currency-exchange', flatColorIconsCurrencyExchange)
  .set('cff:flat-color-icons:electro-devices', flatColorIconsElectroDevices)
  .set('cff:flat-color-icons:export', flatColorIconsExport)
  .set('cff:flat-color-icons:internal', flatColorIconsInternal)

  .set('cff:ion:alert-circle', ionAlertCircle)
  .set('cff:ion:arrow-undo', ionArrowUndo)
  .set('cff:ion:bar-chart-outline', ionBarChartOutline)
  .set('cff:ion:build', ionBuild)
  .set('cff:ion:calculator', ionCalculator)
  .set('cff:ion:calendar-outline', ionCalendarOutline)
  .set('cff:ion:chevron-down', ionChevronDown)
  .set('cff:ion:chevron-down-circle-outline', ionChevronDownCircleOutline)
  .set('cff:ion:chevron-up-circle-outline', ionChevronUpCircleOutline)
  .set('cff:ion:clipboard-outline', ionClipboardOutline)
  .set('cff:ion:download-outline', ionDownloadOutline)
  .set('cff:ion:exit-outline', ionExitOutline)
  .set('cff:ion:eye-off-outline', ionEyeOffOutline)
  .set('cff:ion:eye-outline', ionEyeOutline)
  .set('cff:ion:flower-sharp', ionFlowerSharp)
  .set('cff:ion:funnel', ionFunnel)
  .set('cff:ion:gift-outline', ionGiftOutline)
  .set('cff:ion:hammer-outline', ionHammerOutline)
  .set('cff:ion:help-circle', ionHelpCircle)
  .set('cff:ion:help-sharp', ionHelpSharp)
  .set('cff:ion:information-circle-outline', ionInformationCircleOutline)
  .set('cff:ion:library-outline', ionLibraryOutline)
  .set('cff:ion:list-outline', ionListOutline)
  .set('cff:ion:play-circle-outline', ionPlayCircleOutline)
  .set('cff:ion:play-forward-circle-outline', ionPlayForwardCircleOutline)
  .set('cff:ion:remove-outline', ionRemoveOutline)
  .set('cff:ion:search', ionSearch)
  .set('cff:ion:shield-checkmark', ionShieldCheckmark)
  .set('cff:ion:sparkles-outline', ionSparklesOutline)
  .set('cff:ion:trash-sharp', ionTrashSharp)
  .set('cff:ion:wallet-outline', ionWalletOutline)

  .set('cff:mdi:engine-off-outline', mdiEngineOffOutline)
  .set('cff:mdi:engine-outline', mdiEngineOutline);

export function initialize(/*application: Application*/): void {
  // override default api to not reach iconify api
  addAPIProvider('', { resources: [''] });
  // disable iconify cache because now all icons are included in the ember bundle
  disableCache('all');

  icons.forEach((data, name) => addIcon(name, data));
}

export default {
  initialize,
};
