import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';

import type ApplicationService from 'cryptofiscafacile-gui/services/application';
import type WasmService from 'cryptofiscafacile-gui/services/wasm';
import type IntlService from 'ember-intl/services/intl';
export default class ApplicationRoute extends Route {
  @service declare application: ApplicationService;
  @service declare intl: IntlService;
  @service declare wasm: WasmService;

  async beforeModel() {
    this.intl.setLocale(['fr']);

    await this.application.detectFeaturesTask.perform();
    this.application.performStartupChecks();
    this.wasm.updateDefinitonsTask.perform();
  }
}
