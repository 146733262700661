import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<span\n  data-title=\"Bienvenue\"\n  data-intro=\"L'état du moteur de l'outil (la partie qui gère les données et les calculs de CFF). Celui ci peut-être:\n  <ul>\n    <li>Jaune: En cours de démarrage</li>\n    <li>Vert: En cours de fonctionnement</li>\n    <li>Rouge: ARRÊTÉ, la page doit être rechargée pour relancer le moteur</li>\n  </ul>\"\n  class=\"head-icon {{this.status}}\"\n  {{offcanvas-hider \"offcanvas-filter\"}}\n>\n  {{#if (eq this.wasm.status \"HALTED\")}}\n    <App::Icon @icon=\"mdi:engine-off-outline\" height=\"25\" />\n  {{else}}\n    <App::Icon @icon=\"mdi:engine-outline\" height=\"25\" />\n  {{/if}}\n  {{#if this.tooltipText}}\n    <BsTooltip @title={{this.tooltipText}} />\n  {{/if}}\n</span>", {"contents":"<span\n  data-title=\"Bienvenue\"\n  data-intro=\"L'état du moteur de l'outil (la partie qui gère les données et les calculs de CFF). Celui ci peut-être:\n  <ul>\n    <li>Jaune: En cours de démarrage</li>\n    <li>Vert: En cours de fonctionnement</li>\n    <li>Rouge: ARRÊTÉ, la page doit être rechargée pour relancer le moteur</li>\n  </ul>\"\n  class=\"head-icon {{this.status}}\"\n  {{offcanvas-hider \"offcanvas-filter\"}}\n>\n  {{#if (eq this.wasm.status \"HALTED\")}}\n    <App::Icon @icon=\"mdi:engine-off-outline\" height=\"25\" />\n  {{else}}\n    <App::Icon @icon=\"mdi:engine-outline\" height=\"25\" />\n  {{/if}}\n  {{#if this.tooltipText}}\n    <BsTooltip @title={{this.tooltipText}} />\n  {{/if}}\n</span>","moduleName":"cryptofiscafacile-gui/components/app/header/engine-indicator.hbs","parseOptions":{"srcName":"cryptofiscafacile-gui/components/app/header/engine-indicator.hbs"}});
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';

import { EngineStatus } from 'cryptofiscafacile-gui/enums/engine-status';

import type Wasm from 'cryptofiscafacile-gui/services/wasm';

export default class AppHeaderEngineIndicator extends Component {
  @service declare wasm: Wasm;

  get status() {
    return `engine-${this.wasm.status.toLowerCase()}`;
  }

  get tooltipText() {
    switch (this.wasm.status) {
      case EngineStatus.HALTED:
        return 'Le moteur CFF est arrêté, merci de recharger la page';
      case EngineStatus.LOADING:
        return 'Le moteur CFF démarre';
      case EngineStatus.RUNNING:
        return 'Le moteur CFF tourne parfaitement';
      default:
        throw new Error('Unknown status');
    }
  }
}
