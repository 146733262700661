import { tracked } from '@glimmer/tracking';
import { debug } from '@ember/debug';
import Service, { inject as service } from '@ember/service';
import { waitFor } from '@ember/test-waiters';

import config from 'cryptofiscafacile-gui/config/environment';
import { dropTask } from 'ember-concurrency';

import type WasmService from './wasm';
import type { Owner } from '@ember/test-helpers/build-owner';

export default class ApplicationService extends Service {
  @service declare wasm: WasmService;

  browserDetected = '';

  @tracked hasIndexedDb = false;

  @tracked helpTarget = '#intro-synth';

  @tracked newUser = false;
  @tracked newVersion = false;
  @tracked firstTourDone = false;

  @tracked persistedVersion?: string;

  constructor(owner: Owner) {
    super(owner);
    this.testBrowser();
  }

  get version() {
    return config.APP.version.split('+')[0] ?? '';
  }

  checkHasIndexedDb(): Promise<boolean> {
    return new Promise((resolve /* , reject */) => {
      const hasIDb = 'indexedDB' in window;

      if (!hasIDb) resolve(false);

      let request = window.indexedDB.open('MyTestDatabase');

      request.onerror = function () {
        resolve(false);
      };

      request.onsuccess = function () {
        resolve(true);
      };

      let requestClose = window.indexedDB.deleteDatabase('MyTestDatabase');

      requestClose.onerror = function () {
        debug('Error on MyTestDatabase removal (Test for idb)');
      };

      requestClose.onsuccess = function () {
        debug('MyTestDatabase removed (Test for idb)');
      };
    });
  }

  detectFeaturesTask = dropTask(async () => {
    this.hasIndexedDb = await this.checkHasIndexedDb();
  });

  private testBrowser() {
    // Opera 8.0+
    //Example: Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/96.0.4664.93 Safari/537.36 OPR/82.0.4227.33
    // TODO Improve detection of Opera
    let isOpera = navigator.userAgent.indexOf(' OPR/') >= 0;

    // Firefox 1.0+
    //Example: Mozilla/5.0 (Windows NT 10.0; Win64; x64; rv:95.0) Gecko/20100101 Firefox/95.0
    let isFirefox = navigator.userAgent.indexOf(' Firefox/') != -1;

    // Internet Explorer 6-11
    //TODO find a solution to detect IE

    // Edge 20+
    //Example: Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/96.0.4664.93 Safari/537.36 Edg/96.0.1054.53
    let isEdge = navigator.userAgent.indexOf(' Edg/') != -1;

    // Chrome 1 - 79
    //Example: Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/96.0.4664.93 Safari/537.36
    // let isChrome = !!window.chrome && (!!window.chrome.webstore || !!window.chrome.runtime);

    // Blink engine detection
    let isBlink = isOpera && !!window.CSS;

    // Safari 3.0+ "[object HTMLElementConstructor]"
    // TODO: Add detection for Safari

    if (isOpera) {
      this.browserDetected = 'Opera';
    } else if (isFirefox) {
      this.browserDetected = 'Firefox';
    } else if (isEdge) {
      this.browserDetected = 'Edge';
    } else if (isBlink) {
      this.browserDetected = 'Blink';
    } else {
      this.browserDetected = 'Chrome';
    }
  }

  @waitFor
  async performStartupChecks() {
    const appVersionEntry = await this.wasm.getApplicationEntryTask.perform('application.version');

    if (appVersionEntry?.value) {
      this.persistedVersion = appVersionEntry?.value;
    }

    await this.wasm.getHistoryTask.perform();

    if (!this.persistedVersion && this.wasm.history?.length === 0) {
      this.newUser = true;
    } else if (!this.persistedVersion || this.persistedVersion !== this.version) {
      this.newVersion = true;
    }
  }
}

// DO NOT DELETE: this is how TypeScript knows how to look up your services.
declare module '@ember/service' {
  interface Registry {
    'application-service': ApplicationService;
  }
}
