import { tracked } from '@glimmer/tracking';
import Controller from '@ember/controller';

import Option from 'cryptofiscafacile-gui/components/option';
import { SortOrder } from 'cryptofiscafacile-gui/enums/sort-order';
export default class TransactionsController extends Controller {
  @tracked userPage = 1;

  @tracked pageSize?: number;

  @tracked selectedCategory = [Option.ALL];
  @tracked selectedDates: Date[] = [];
  @tracked selectedCoins = [Option.ALL];
  @tracked selectedWallet = [Option.ALL];

  @tracked dateSortOrder = SortOrder.DESC;
  @tracked pfSortOrder = SortOrder.NONE;
  @tracked catSortOrder = SortOrder.NONE;

  @tracked searchedItem = '';
}

// DO NOT DELETE: this is how TypeScript knows how to look up your controllers.
declare module '@ember/controller' {
  interface Registry {
    'transactions-controller': TransactionsController;
  }
}
