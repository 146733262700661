import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<BsButton\n  {{download filename=@filename fileUrl=this.fileUrl}}\n  @type={{\"none\"}}\n  class=\"wallet-action-icon\"\n  disabled={{this.downloadingThisFile}}\n  {{on \"click\" this.buildDownloadFile}}\n>\n  {{#if this.downloadingThisFile}}\n    <span class=\"spinner-border spinner-border-sm\" role=\"status\" aria-hidden=\"true\"></span>\n  {{else}}\n    <App::Icon @icon=\"ion:download-outline\" width=\"18\" />\n    <BsTooltip @placement=\"right\">Télécharger fichier</BsTooltip>\n  {{/if}}\n</BsButton>", {"contents":"<BsButton\n  {{download filename=@filename fileUrl=this.fileUrl}}\n  @type={{\"none\"}}\n  class=\"wallet-action-icon\"\n  disabled={{this.downloadingThisFile}}\n  {{on \"click\" this.buildDownloadFile}}\n>\n  {{#if this.downloadingThisFile}}\n    <span class=\"spinner-border spinner-border-sm\" role=\"status\" aria-hidden=\"true\"></span>\n  {{else}}\n    <App::Icon @icon=\"ion:download-outline\" width=\"18\" />\n    <BsTooltip @placement=\"right\">Télécharger fichier</BsTooltip>\n  {{/if}}\n</BsButton>","moduleName":"cryptofiscafacile-gui/components/user-wallets/download.hbs","parseOptions":{"srcName":"cryptofiscafacile-gui/components/user-wallets/download.hbs"}});
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { debug } from '@ember/debug';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';

import type Wasm from 'cryptofiscafacile-gui/services/wasm';
import type { TaskInstance } from 'ember-concurrency';

interface UserWalletsDownloadArgs {
  filename: string;
  type: string;
}

export default class UserWalletsDownload extends Component<UserWalletsDownloadArgs> {
  @service declare wasm: Wasm;

  @tracked downloadTask?: TaskInstance<void>;

  @tracked fileUrl = '';

  get downloadingThisFile() {
    return this.downloadTask?.isRunning;
  }

  get mimeType() {
    switch (this.args.type) {
      case 'CSV': {
        return 'text/csv';
      }
      case 'JSON': {
        return 'application/json';
      }
      case 'XLSX': {
        return 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
      }
    }

    return undefined;
  }

  @action
  async buildDownloadFile() {
    debug('Download file: ' + this.args.filename);
    this.downloadTask = this.wasm.downloadFileTask.perform(this.args.filename);
    await this.downloadTask;

    if (this.wasm.fileContent) {
      // Convert Uint8Array to ArrayBuffer
      let buf = this.wasm.fileContent.buffer.slice(
        this.wasm.fileContent.byteOffset,
        this.wasm.fileContent.byteLength + this.wasm.fileContent.byteOffset,
      );

      //Create blob from the ArrayBuffer

      let blob = new Blob([buf], { type: this.mimeType });

      // Then create the URI, for file download
      this.fileUrl = window.URL.createObjectURL(blob);
    }
  }
}

// DO NOT DELETE: this is how TypeScript knows how to look up your controllers.
declare module '@glimmer/component' {
  interface Registry {
    'user-wallets-download': UserWalletsDownload;
  }
}
