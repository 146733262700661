import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';

import type ApplicationService from 'cryptofiscafacile-gui/services/application';
import type WasmService from 'cryptofiscafacile-gui/services/wasm';

export default class WalletsRoute extends Route {
  @service declare application: ApplicationService;
  @service declare wasm: WasmService;

  model() {
    this.wasm.getHistoryTask.perform();
    this.wasm.getWalletsTask.perform();
  }

  activate() {
    this.application.helpTarget = '#intro-wallet';
  }
}
