import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<BsButton\n  @type={{\"none\"}}\n  class=\"wallet-action-icon {{if (eq this.deletingFile (not this.deletingThisFile)) 'wallet-action-icon-locked'}}\"\n  disabled={{this.deletingFile}}\n  {{on \"click\" this.delete}}\n>\n  {{#if this.deletingThisFile}}\n    <span class=\"spinner-border spinner-border-sm\" role=\"status\" aria-hidden=\"true\"></span>\n  {{else}}\n    <App::Icon @icon=\"ion:trash-sharp\" width=\"18\" />\n    <BsTooltip @placement=\"right\">Supprimer fichier</BsTooltip>\n  {{/if}}\n</BsButton>", {"contents":"<BsButton\n  @type={{\"none\"}}\n  class=\"wallet-action-icon {{if (eq this.deletingFile (not this.deletingThisFile)) 'wallet-action-icon-locked'}}\"\n  disabled={{this.deletingFile}}\n  {{on \"click\" this.delete}}\n>\n  {{#if this.deletingThisFile}}\n    <span class=\"spinner-border spinner-border-sm\" role=\"status\" aria-hidden=\"true\"></span>\n  {{else}}\n    <App::Icon @icon=\"ion:trash-sharp\" width=\"18\" />\n    <BsTooltip @placement=\"right\">Supprimer fichier</BsTooltip>\n  {{/if}}\n</BsButton>","moduleName":"cryptofiscafacile-gui/components/removal/index.hbs","parseOptions":{"srcName":"cryptofiscafacile-gui/components/removal/index.hbs"}});
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { debug } from '@ember/debug';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';

import type Wasm from 'cryptofiscafacile-gui/services/wasm';
import type { TaskInstance } from 'ember-concurrency';

interface RemovalArgs {
  filename: string;
}

export default class Removal extends Component<RemovalArgs> {
  @service declare wasm: Wasm;

  @tracked deletingTask?: TaskInstance<void>;

  get deletingFile() {
    return this.wasm.deleteFileTask.isRunning;
  }

  get deletingThisFile() {
    return this.deletingTask?.isRunning;
  }

  @action
  async delete() {
    debug('Removal of file: ' + this.args.filename);
    this.deletingTask = this.wasm.deleteFileTask.perform(this.args.filename);
    await this.deletingTask;
  }
}

// DO NOT DELETE: this is how TypeScript knows how to look up your controllers.
declare module '@glimmer/component' {
  interface Registry {
    removal: Removal;
  }
}
