import { SortOrder } from 'cryptofiscafacile-gui/enums/sort-order';

export default class Sorts {
  private constructor() {
    // Utility class
  }

  public static date<T>(propertySupplier: (object: T) => Date, order: SortOrder) {
    return (a: T, b: T) => {
      const timeA = propertySupplier(a)?.getTime();
      const timeB = propertySupplier(b)?.getTime();

      switch (order) {
        case SortOrder.ASC:
          return timeA - timeB;
        case SortOrder.DESC:
          return timeB - timeA;
        case SortOrder.NONE:
          return 0;
      }
    };
  }

  public static text<T>(propertySupplier: (object: T) => string, order: SortOrder) {
    return (a: T, b: T) => {
      const propA = propertySupplier(a) ?? '';
      const propB = propertySupplier(b) ?? '';

      switch (order) {
        case SortOrder.ASC:
          return propA.localeCompare(propB);
        case SortOrder.DESC:
          return propB.localeCompare(propA);
        case SortOrder.NONE:
          return 0;
      }
    };
  }
}
