import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<div\n  class=\"help mb-4 me-4 pulsate-fwd\"\n  data-title=\"Bienvenue\"\n  data-intro=\"N'oubliez pas, si vous êtes bloqué, vous pouvez cliquer à tout moment sur l'aide interactive en bas à droite de chaque page, de vous rendre du la page Documentation, voire de contacter l'équipe qui sera là pour vous aider... Bonne déclaration !\"\n>\n  <button class=\"help-button\" type=\"button\" {{intro @target autoStart=this.autoStart onExit=this.onIntroExit}}>\n    <App::Icon @icon=\"ion:help-circle\" width=\"50\" />\n    <BsTooltip class=\"zindex\" @placement=\"left\">Cliquez ici pour ouvrir l'aide interactive</BsTooltip>\n  </button>\n</div>", {"contents":"<div\n  class=\"help mb-4 me-4 pulsate-fwd\"\n  data-title=\"Bienvenue\"\n  data-intro=\"N'oubliez pas, si vous êtes bloqué, vous pouvez cliquer à tout moment sur l'aide interactive en bas à droite de chaque page, de vous rendre du la page Documentation, voire de contacter l'équipe qui sera là pour vous aider... Bonne déclaration !\"\n>\n  <button class=\"help-button\" type=\"button\" {{intro @target autoStart=this.autoStart onExit=this.onIntroExit}}>\n    <App::Icon @icon=\"ion:help-circle\" width=\"50\" />\n    <BsTooltip class=\"zindex\" @placement=\"left\">Cliquez ici pour ouvrir l'aide interactive</BsTooltip>\n  </button>\n</div>","moduleName":"cryptofiscafacile-gui/components/help/index.hbs","parseOptions":{"srcName":"cryptofiscafacile-gui/components/help/index.hbs"}});
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';

import type ApplicationService from 'cryptofiscafacile-gui/services/application';
import type WasmService from 'cryptofiscafacile-gui/services/wasm';

export default class Help extends Component {
  @service declare application: ApplicationService;
  @service declare wasm: WasmService;

  get autoStart() {
    return this.application.hasIndexedDb && this.application.newUser && !this.application.firstTourDone;
  }

  @action
  onIntroExit() {
    if (this.autoStart) {
      this.wasm.updateApplicationEntryTask.perform({
        key: 'application.version',
        value: this.application.version,
      });

      this.application.firstTourDone = true;
    }
  }
}

// DO NOT DELETE: this is how TypeScript knows how to look up your controllers.
declare module '@glimmer/component' {
  interface Registry {
    help: Help;
  }
}
