import Service from '@ember/service';

import { task } from 'ember-concurrency';

export class ResponseBody<T> {
  constructor(
    readonly response: Response,
    readonly body: T,
  ) {}
}

export default class FetchService extends Service {
  abortableFetchArrayBufferTask = task(async (url: string) => {
    const controller = new AbortController();
    const signal = controller.signal;

    try {
      let response;

      response = await fetch(url, { signal });

      return new ResponseBody(response, await response.arrayBuffer());
    } finally {
      if (signal) {
        controller.abort();
      }
    }
  });
}

// DO NOT DELETE: this is how TypeScript knows how to look up your services.
declare module '@ember/service' {
  interface Registry {
    'fetch-service': FetchService;
  }
}
