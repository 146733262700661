import Ember from 'ember';
import { debug } from '@ember/debug';
import Service, { inject as service } from '@ember/service';

import config from 'cryptofiscafacile-gui/config/environment';
import { EngineStatus } from 'cryptofiscafacile-gui/enums/engine-status';
import { loadCff2WasmProxy } from 'cryptofiscafacile-wasm';
import { all, dropTask, enqueueTask, restartableTask, task, timeout } from 'ember-concurrency';
import { tracked } from 'tracked-built-ins';

import type ApplicationService from './application';
import type FetchService from './fetch';
import type ManifestEntry from 'cryptofiscafacile-gui/objects/manifest-entry';
import type {
  ApiData,
  ApplicationEntry,
  Cerfa3916Account,
  Cff2Wasm,
  CoinDetailData,
  ConsistencyErrors,
  DbName,
  Definition,
  FileUsageStatus,
  ReportsData,
  SynthBalance,
  SynthCoin,
  SynthEvolution,
  SynthStatsGainsLosses,
  SynthStatTotalValue,
  SynthTxTypes,
  ToolsData,
  Transaction,
  TxCategory,
  UserData,
  Wallet,
  WalletDefinition,
} from 'cryptofiscafacile-wasm';
import type { TaskInstance } from 'ember-concurrency';
import type IntlService from 'ember-intl/services/intl';

export default class WasmService extends Service {
  private static readonly PREFIX = '/definitions/';

  @service declare application: ApplicationService;
  @service declare intl: IntlService;
  @service declare fetch: FetchService;

  reg = /(\r\n|\n|\r)/gm;

  errorMessageReg = new RegExp('^[a-zA-Z]+\\.{1}[a-zA-Z\\.]+$');

  @tracked verboseActivated = false;

  @tracked transactions: Transaction[] = [];
  @tracked userInfos?: UserData;

  @tracked consistencyCategVsToFrom?: ConsistencyErrors;
  @tracked consistencyNegativeBalance?: ConsistencyErrors;
  @tracked consistencyAlmost?: ConsistencyErrors;

  @tracked history: FileUsageStatus[] = [];

  @tracked reports?: ReportsData;

  @tracked stocksXLSX?: Uint8Array;
  @tracked cerfa3916?: Uint8Array;
  @tracked errorMessage3916 = '';

  @tracked fileContent?: Uint8Array;

  @tracked tools?: ToolsData;

  toolsErrors: WeakMap<ApiData, string> = tracked(new WeakMap());
  toolsProgress: WeakMap<ApiData, number> = tracked(new WeakMap());
  toolsTasks: WeakMap<ApiData, TaskInstance<void>[]> = tracked(new WeakMap());

  coinsDetail: Map<string, CoinDetailData> = tracked(new Map());

  @tracked alreadyDefinitions?: Definition[];

  @tracked digestFilesTaskFilesAmount = 0;

  @tracked digestFilesTaskUseFileTasks: TaskInstance<void>[] = [];

  #cff2Wasm?: Cff2Wasm;
  @tracked status = EngineStatus.HALTED;
  loadCff2WasmTask = enqueueTask(async () => {
    if (!this.#cff2Wasm && this.application.hasIndexedDb) {
      this.status = EngineStatus.LOADING;
      this.#cff2Wasm = await loadCff2WasmProxy('/cff2.wasm', () => (this.status = EngineStatus.HALTED), !Ember.testing);
      this.status = EngineStatus.RUNNING;
    }
  });

  // synth dates
  defaultEndDate: Date = ((d) => new Date(d.setDate(d.getDate() - 1)))(new Date());
  defaultStartDate: Date = ((d) => new Date(d.setDate(d.getDate() - 366)))(new Date());

  @tracked synthBalances: SynthBalance[] = [];
  getSynthBalancesTask = restartableTask(async (date: Date, wallet?: Wallet) => {
    await this.loadCff2WasmTask.perform();

    if (this.#cff2Wasm) {
      this.synthBalances = await this.#cff2Wasm.getSynthBalances(date, wallet?.name);
    }
  });

  @tracked synthEvolution: SynthEvolution[] = [];
  getSynthEvolutionTask = restartableTask(async (start: Date, end: Date, wallet?: Wallet) => {
    await this.loadCff2WasmTask.perform();

    if (this.#cff2Wasm) {
      this.synthEvolution = await this.#cff2Wasm.getSynthEvolution(start, end, wallet?.name);
    }
  });

  @tracked synthPorfolio: SynthCoin[] = [];
  getSynthPortfolioTask = restartableTask(async (date: Date, wallet?: Wallet) => {
    await this.loadCff2WasmTask.perform();

    if (this.#cff2Wasm) {
      this.synthPorfolio = await this.#cff2Wasm.getSynthPortfolio(date, wallet?.name);
    }
  });

  @tracked synthStatTotalValue?: SynthStatTotalValue;
  getSynthStatTotalValueTask = restartableTask(async (date: Date, wallet?: Wallet) => {
    await this.loadCff2WasmTask.perform();

    if (this.#cff2Wasm) {
      this.synthStatTotalValue = await this.#cff2Wasm.getSynthStatTotalValue(date, wallet?.name);
    }
  });

  @tracked synthStatsGainsLosses?: SynthStatsGainsLosses;
  @tracked synthStatsGainsError?: Error;
  getSynthStatsGainsLossesTask = restartableTask(async (date: Date) => {
    await this.loadCff2WasmTask.perform();

    if (this.#cff2Wasm) {
      try {
        this.synthStatsGainsLosses = await this.#cff2Wasm.getSynthStatsGainsLosses(date);
        this.synthStatsGainsError = undefined;
      } catch (error) {
        this.synthStatsGainsLosses = undefined;
        this.synthStatsGainsError = error as Error;
      }
    }
  });

  @tracked synthTxTypes?: SynthTxTypes;
  getSynthTxTypesTask = restartableTask(async (start: Date, end: Date, wallet?: Wallet) => {
    await this.loadCff2WasmTask.perform();

    if (this.#cff2Wasm) {
      this.synthTxTypes = await this.#cff2Wasm.getSynthTxTypes(start, end, wallet?.name);
    }
  });

  getUserInfosTask = task(async () => {
    await this.loadCff2WasmTask.perform();

    if (this.#cff2Wasm) {
      this.userInfos = await this.#cff2Wasm.getUserInfos();

      if (!this.userInfos?.proxy) {
        await this.saveConfigTask.perform(config.APP.proxyUrl);
      }
    }
  });

  async updateDefinitionCsvDef(csv: string) {
    await this.#updateDefinitionDef(`CSV_Def_${csv}.json`);
  }

  async updateDefinitionWalletDef(csv: string) {
    await this.#updateDefinitionDef(`Wallet_Def_${csv}.json`);
  }

  async #updateDefinitionDef(filename: string) {
    const manifest = await this.#fetchManifest();

    const entry = manifest.find((e) => e.name === filename);

    if (entry) {
      const jsonDef = await fetch(WasmService.PREFIX + entry.name);
      const arrayBuffer = await jsonDef.arrayBuffer();

      if (arrayBuffer) {
        await this.updateDefinitionTask.perform(entry, arrayBuffer);
      }
    }
  }

  updateDefinitonsTask = task(async () => {
    await this.loadCff2WasmTask.perform();

    if (this.#cff2Wasm) {
      await this.#updateDefinitions();
    }
  });

  async #updateDefinitions() {
    //1. Fetch manifest.json and check if OFFLINE or ONLINE
    const manifest = await this.#fetchManifest();

    //2. Get already installed definitions
    this.alreadyDefinitions = await this.#cff2Wasm?.getDefinitions();

    //3. For each file, if alreadyDefinitions version < manifest version, perfor a fetch of each remote csv-def
    const newEntry = manifest.filter(
      (entry) => (this.alreadyDefinitions?.find((def) => def.name === entry.name)?.version ?? 0) < entry.version,
    );

    const defsTasks = newEntry.map((entry) =>
      this.fetch.abortableFetchArrayBufferTask.perform(WasmService.PREFIX + entry.name),
    );

    const definitions = await all(defsTasks);

    for (const manifestEntry of newEntry) {
      const link = WasmService.PREFIX + manifestEntry.name;

      //4. Then transform it to Uint8Array via arrayBuffer!
      const arrayBuffer = definitions.find((respBody) => respBody.response.url.endsWith(link))?.body;

      //5. Perform a useFile to update local BDD IDB, following by a setDefinition()
      if (arrayBuffer) {
        await this.updateDefinitionTask.perform(manifestEntry, arrayBuffer);
      }
    }
  }

  private updateDefinitionTask = enqueueTask(async (entry: ManifestEntry, arrayBuffer: ArrayBuffer) => {
    await this.loadCff2WasmTask.perform();

    //5. Perform a useFile to update local BDD IDB, following by a setDefinition()
    try {
      if (this.#cff2Wasm) {
        await this.#cff2Wasm.useFile(entry.name, new Uint8Array(arrayBuffer));
        await this.#cff2Wasm.setDefinition(entry.name, entry.version);
      }
    } catch (error) {
      debug('Error in useFile for updateDefinitons: ' + (error as Error).message);
    }
  });

  async #fetchManifest() {
    try {
      let response = await fetch(WasmService.PREFIX + 'manifest.json');

      return (await response.json()) as ManifestEntry[];
    } catch (error) {
      debug('Error in fetch(): User is OFFLINE - ' + (error as Error).message);
    }

    return [];
  }

  getDefinitonsTask = task(async () => {
    await this.loadCff2WasmTask.perform();

    if (this.#cff2Wasm) {
      return await this.#cff2Wasm.getDefinitions();
    } else {
      return [];
    }
  });

  setDebugTask = task(async (activate: boolean) => {
    await this.loadCff2WasmTask.perform();

    if (this.#cff2Wasm) {
      this.verboseActivated = await this.#cff2Wasm.setDebug(activate);
    }
  });

  setFetchRates = task(async (fetchRates: boolean) => {
    await this.loadCff2WasmTask.perform();

    if (this.#cff2Wasm) {
      await this.#cff2Wasm.setFetchRates(fetchRates);
    }
  });

  setupFrontendTestTask = task(async (fetchRates: boolean) => {
    if (!Ember.testing) {
      throw new Error('this task is only callable in tests');
    }

    await this.loadCff2WasmTask.perform();

    if (this.#cff2Wasm) {
      await this.#cff2Wasm.setupFrontendTest(fetchRates);
    }
  });

  cleanDbsTask = task(async (names: DbName[]) => {
    await this.loadCff2WasmTask.perform();

    if (this.#cff2Wasm) {
      await this.#cff2Wasm.cleanDbs(names);
    }
  });

  getTransactionsTask = task(async () => {
    await this.loadCff2WasmTask.perform();

    if (this.#cff2Wasm) {
      this.transactions = await this.#cff2Wasm.getTransactions();
    }
  });

  @tracked consistencyCategVsToFromError = false;
  @tracked consistencyCategVsToFromErrorMessage = '';

  checkConsistencyCategVsToFromTask = task(async () => {
    await this.checkConsistencyCategVsToFrom();
  });

  async checkConsistencyCategVsToFrom() {
    await this.loadCff2WasmTask.perform();

    if (this.#cff2Wasm) {
      try {
        this.consistencyCategVsToFromError = false;
        this.consistencyCategVsToFrom = await this.#cff2Wasm.checkConsistencyCategVsToFrom();
      } catch (error) {
        this.consistencyCategVsToFromErrorMessage = (error as Error).message;
        this.consistencyCategVsToFromError = true;
        debug('Error in consistency Categ vs To/From: ' + (error as Error).message);
      }
    }
  }

  @tracked consistencyNegativeBalanceError = false;
  @tracked consistencyNegativeBalanceErrorMessage = '';
  checkConsistencyNegativeBalanceTask = task(async () => {
    await this.loadCff2WasmTask.perform();

    if (this.#cff2Wasm) {
      try {
        this.consistencyNegativeBalanceError = false;
        this.consistencyNegativeBalance = await this.#cff2Wasm.checkConsistencyNegativeBalance();
      } catch (error) {
        this.consistencyNegativeBalanceErrorMessage = (error as Error).message;
        this.consistencyNegativeBalanceError = true;
        debug('Error in consistency Negative Balance: ' + (error as Error).message);
      }
    }
  });

  @tracked consistencyAlmostError = false;
  @tracked consistencyAlmostMessage = '';

  checkConsistencyAlmostTransferTask = task(async () => {
    await this.loadCff2WasmTask.perform();

    if (this.#cff2Wasm) {
      try {
        this.consistencyAlmostError = false;
        this.consistencyAlmost = await this.#cff2Wasm.checkConsistencyAlmostTransfer();
      } catch (error) {
        this.consistencyAlmostMessage = (error as Error).message;
        this.consistencyAlmostError = true;
        debug('Error in consistency Almost transfer: ' + (error as Error).message);
      }
    }
  });

  getHistoryTask = dropTask(async () => {
    await this.loadCff2WasmTask.perform();

    if (this.#cff2Wasm) {
      this.history = (await this.#cff2Wasm.getHistory(false)).reverse();
    }
  });

  @tracked walletDefs?: WalletDefinition[];

  getWalletDefinitionsTask = task(async () => {
    await this.loadCff2WasmTask.perform();

    if (this.#cff2Wasm) {
      this.walletDefs = await this.#cff2Wasm.getWalletDefinitions();
    }
  });

  addEmptyWalletTask = task(async (wallet: string) => {
    await this.loadCff2WasmTask.perform();

    if (this.#cff2Wasm) {
      await this.#cff2Wasm.addEmptyWallet(wallet);
    }
  });

  @tracked wallets: Wallet[] = [];
  getWalletsTask = task(async () => {
    await this.loadCff2WasmTask.perform();

    if (this.#cff2Wasm) {
      this.wallets = await this.#cff2Wasm.getWallets();
    }
  });

  getReportsTask = task(async () => {
    await this.loadCff2WasmTask.perform();

    if (this.#cff2Wasm) {
      this.reports = await this.#cff2Wasm.getReports();
    }
  });

  setCategoryTask = task(async (id: string, newCateg: TxCategory) => {
    await this.loadCff2WasmTask.perform();

    if (this.#cff2Wasm) {
      await this.#cff2Wasm.setCategory(id, newCateg);
    }
  });

  revertCategoryTask = task(async (id: string) => {
    await this.loadCff2WasmTask.perform();

    if (this.#cff2Wasm) {
      await this.#cff2Wasm.revertCateg(id);
    }
  });

  mergeTransactionsTask = task(async (srcID: string, dstID: string) => {
    await this.loadCff2WasmTask.perform();

    if (this.#cff2Wasm) {
      try {
        await this.#cff2Wasm.mergeTransactions(srcID, dstID);
      } catch (error) {
        debug('Error in merge transactions : ' + (error as Error).message);
      }
    }
  });

  unMergeTransactionsTask = task(async (srcID: string) => {
    await this.loadCff2WasmTask.perform();

    if (this.#cff2Wasm) {
      await this.#cff2Wasm.unMergeTransactions(srcID);
    }
  });

  setValueTask = task(async (srcID: string, value: number[]) => {
    await this.loadCff2WasmTask.perform();

    if (this.#cff2Wasm) {
      await this.#cff2Wasm.setValue(srcID, value);
    }
  });

  saveUserTask = task(
    async (name: string | undefined, surname: string | undefined, email: string | undefined): Promise<void> => {
      await this.loadCff2WasmTask.perform();

      if (this.#cff2Wasm) {
        await this.#cff2Wasm.saveUser(name, surname, email);
      }
    },
  );

  saveAccountsTask = task(async (wallet: string, accounts: Cerfa3916Account[]): Promise<void> => {
    await this.loadCff2WasmTask.perform();

    if (this.#cff2Wasm) {
      await this.#cff2Wasm.saveAccounts(wallet, accounts);
    }
  });

  saveChoiceBNCTask = task(async (year: number, cashin: boolean): Promise<void> => {
    await this.loadCff2WasmTask.perform();

    if (this.#cff2Wasm) {
      await this.#cff2Wasm.saveChoiceCashIn(year, cashin);
    }
  });

  getStocksXlsxTask = task(async () => {
    await this.loadCff2WasmTask.perform();

    if (this.#cff2Wasm) {
      this.stocksXLSX = await this.#cff2Wasm.getStocksXLSX();
    }
  });

  get3916XlsxTask = task(async () => {
    await this.loadCff2WasmTask.perform();

    if (this.#cff2Wasm) {
      this.cerfa3916 = undefined;
      this.errorMessage3916 = '';

      try {
        this.cerfa3916 = await this.#cff2Wasm.get3916XLSX();
      } catch (error) {
        this.errorMessage3916 = (error as Error).message;
        debug('CERFA 3916 unable to generate: ' + (error as Error).message);
      }
    }
  });

  get2086ResultsTask = task(async () => {
    await this.loadCff2WasmTask.perform();

    if (this.#cff2Wasm) {
      try {
        return await this.#cff2Wasm.get2086Results();
      } catch (error) {
        this.errorMessage2086 = (error as Error).message;
        debug('CERFA 2086 unable to generate: ' + (error as Error).message);
      }
    }

    return undefined;
  });

  @tracked cerfa2086?: Uint8Array;
  @tracked errorMessage2086 = '';
  get2086XlsxTask = task(async () => {
    await this.loadCff2WasmTask.perform();

    if (this.#cff2Wasm) {
      this.cerfa2086 = undefined;
      this.errorMessage2086 = '';

      try {
        this.cerfa2086 = await this.#cff2Wasm.get2086Xlsx();
      } catch (error) {
        this.errorMessage2086 = (error as Error).message;
        debug('CERFA 2086 unable to generate: ' + (error as Error).message);
      }
    }
  });

  @tracked cerfa2086UpToToday?: Uint8Array;
  @tracked errorMessage2086UpToToday = '';
  get2086XlsxUpToTodayTask = task(async () => {
    await this.loadCff2WasmTask.perform();

    if (this.#cff2Wasm) {
      this.cerfa2086UpToToday = undefined;
      this.errorMessage2086UpToToday = '';

      try {
        this.cerfa2086UpToToday = await this.#cff2Wasm.get2086XlsxUpToToday();
      } catch (error) {
        this.errorMessage2086UpToToday = (error as Error).message;
        debug('CERFA 2086 up to today unable to generate: ' + (error as Error).message);
      }
    }
  });

  getApi(provider: ApiData, arg1: string, arg2: string, arg3: string, remember: boolean) {
    const taskInstance = this.getAPITask.perform(provider, arg1, arg2, arg3, remember);

    const tasks = this.toolsTasks.get(provider) ?? [];

    tasks.push(taskInstance);
    this.toolsTasks.set(provider, tasks);

    taskInstance.finally(() => {
      this.toolsTasks.set(provider, this.toolsTasks.get(provider)?.filter((t) => t !== taskInstance) ?? []);
    });
  }

  private getAPITask = task(async (provider: ApiData, arg1: string, arg2: string, arg3: string, remember: boolean) => {
    await this.loadCff2WasmTask.perform();

    if (this.#cff2Wasm) {
      this.toolsErrors.delete(provider);
      this.getApiProgressTask.perform(provider);

      try {
        await this.#cff2Wasm.getApi(provider.name, arg1, arg2, arg3, remember);
      } catch (error) {
        debug('Error in getApi:' + (error as Error).message);
        this.toolsErrors.set(provider, (error as Error).message);
      }
    }
  });

  getApiProgressTask = task(async (provider: ApiData) => {
    await this.loadCff2WasmTask.perform();

    if (this.#cff2Wasm) {
      let progress = 0;

      do {
        progress = await this.#cff2Wasm.getApiProgress(provider.name);

        this.toolsProgress.set(provider, progress);

        if (Ember.testing) {
          break;
        }

        timeout(1000);
      } while (progress < 100);
    }
  });

  reloadIDBTask = task(async () => {
    await this.loadCff2WasmTask.perform();

    if (this.#cff2Wasm) {
      await this.#cff2Wasm.reloadIDB();
    }
  });

  getToolsTask = task(async () => {
    await this.loadCff2WasmTask.perform();

    if (this.#cff2Wasm && !this.tools) {
      this.tools = await this.#cff2Wasm.getTools();
    }
  });

  // for now useFileTask is enqueued because wasm useFile does not permit concurrency yet
  useFileTask = enqueueTask(async (file: File) => {
    await this.loadCff2WasmTask.perform();

    if (this.#cff2Wasm) {
      const arrayBuffer = await file.arrayBuffer();

      try {
        this.history = await this.#cff2Wasm.useFile(file.name, new Uint8Array(arrayBuffer));
      } catch (error) {
        debug('Error in useFile: Empty file or ' + (error as Error).message);
      }
    }
  });

  digestFilesTask = dropTask(async (files: File[]) => {
    this.digestFilesTaskFilesAmount = files.length;

    const tasks = files.map((f) => this.useFileTask.perform(f));

    this.digestFilesTaskUseFileTasks = tasks;
    tasks.forEach((t) => t.then(() => (this.digestFilesTaskUseFileTasks = tasks)));
    await Promise.all(this.digestFilesTaskUseFileTasks);
    await this.getWalletsTask.perform();
  });

  deleteFileTask = task(async (filename: string) => {
    await this.loadCff2WasmTask.perform();

    if (this.#cff2Wasm) {
      try {
        this.history = await this.#cff2Wasm.deleteFile(filename);
      } catch (error) {
        debug('Error in useFile: Empty file or ' + (error as Error).message);
      }

      await this.getWalletsTask.perform();
    }
  });

  downloadFileTask = task(async (filename: string) => {
    await this.loadCff2WasmTask.perform();

    if (this.#cff2Wasm) {
      try {
        this.fileContent = await this.#cff2Wasm.downloadFile(filename);
      } catch (error) {
        debug('Error in downloadFile: ' + (error as Error).message);
      }
    }
  });

  saveConfigTask = task(async (proxyUrl: string) => {
    await this.loadCff2WasmTask.perform();

    if (this.#cff2Wasm) {
      if (this.userInfos) {
        const userInfos = this.userInfos;

        userInfos.proxy = proxyUrl;
        this.userInfos = userInfos;
      }

      await this.#cff2Wasm.saveConfig(proxyUrl);
    }
  });

  getCoinDetailTask = task(async (symbol: string) => {
    await this.loadCff2WasmTask.perform();

    if (this.#cff2Wasm) {
      const detail = await this.#cff2Wasm.getCoinDetail(symbol);

      this.coinsDetail.set(symbol, detail);
    }

    return undefined;
  });

  // Application entries
  createApplicationEntryTask = task(async (entry: ApplicationEntry) => {
    await this.loadCff2WasmTask.perform();

    if (this.#cff2Wasm) {
      await this.#cff2Wasm.createApplicationEntry(entry);
    }
  });

  getApplicationEntryTask = task(async (key: string) => {
    await this.loadCff2WasmTask.perform();

    if (this.#cff2Wasm) {
      return await this.#cff2Wasm.getApplicationEntry(key);
    }

    return undefined;
  });

  updateApplicationEntryTask = task(async (entry: ApplicationEntry) => {
    await this.loadCff2WasmTask.perform();

    if (this.#cff2Wasm) {
      await this.#cff2Wasm.updateApplicationEntry(entry);
    }
  });

  translateError(error: string) {
    const errorIntl = error.split('|');

    if (errorIntl.length > 1 || (errorIntl[0] !== undefined && this.errorMessageReg.test(errorIntl[0]))) {
      const options: Record<string, string> = {};

      errorIntl
        .map((opt) => opt.split('='))
        .filter((opt) => opt.length === 2)
        .forEach((opt) => {
          if (opt[0] && opt[1]) {
            options[opt[0]] = opt[1];
          }
        });

      return this.intl.t(errorIntl[0] ? 'wasm.error.' + errorIntl[0] : '', options);
    }

    return error;
  }
}

// DO NOT DELETE: this is how TypeScript knows how to look up your services.
declare module '@ember/service' {
  interface Registry {
    'wasm-service': WasmService;
  }
}
