import { modifier } from 'ember-modifier';

export default modifier((element: HTMLElement /*, positional, named*/) => {
  const handler = (evt: WheelEvent) => {
    evt.preventDefault();
    element.scrollLeft += evt.deltaY;
  };

  element.addEventListener(
    'wheel',
    (evt) => {
      element.scrollLeft += evt.deltaY;
    },
    { passive: true },
  );

  return () => {
    element.removeEventListener('wheel', handler);
  };
});
