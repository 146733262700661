import { tracked } from '@glimmer/tracking';
import { debug } from '@ember/debug';
import Service, { inject as service } from '@ember/service';

import { DbName } from 'cryptofiscafacile-wasm';
import { Dexie } from 'dexie';
import { exportDB, importDB } from 'dexie-export-import';
import { task } from 'ember-concurrency';

import type WasmService from './wasm';

export default class IndexeddbService extends Service {
  @service declare wasm: WasmService;

  @tracked files: File[] = [];
  dbs = [DbName.CFF, DbName.CONFS, DbName.FILES, DbName.TRANSACTIONS, DbName.RATES];

  exportDatabaseTask = task(async (databaseName: string) => {
    const db = await new Dexie(databaseName).open();

    let options = { prettyJson: true };

    try {
      const blob = await exportDB(db, options);
      let file = new File([blob], databaseName);

      this.files.push(file);
    } catch (err) {
      debug('export-database() task failed : ' + (err as Error).message);
    }
  });

  async importDatabase(file: Blob): Promise<IDBDatabase> {
    let db;

    db = await importDB(file);

    return db.backendDB();
  }

  async cleanDatabases() {
    await this.wasm.cleanDbsTask.perform(this.dbs);
  }
}

// DO NOT DELETE: this is how TypeScript knows how to look up your services.
declare module '@ember/service' {
  interface Registry {
    'indexeddb-service': IndexeddbService;
  }
}
