import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<iconify-icon\n  class=\"align-middle\"\n  icon={{this.icon}}\n  style={{if @color (html-safe (concat \"color: \" @color))}}\n  ...attributes\n></iconify-icon>", {"contents":"<iconify-icon\n  class=\"align-middle\"\n  icon={{this.icon}}\n  style={{if @color (html-safe (concat \"color: \" @color))}}\n  ...attributes\n></iconify-icon>","moduleName":"cryptofiscafacile-gui/components/app/icon.hbs","parseOptions":{"srcName":"cryptofiscafacile-gui/components/app/icon.hbs"}});
import Component from '@glimmer/component';
import { warn } from '@ember/debug';

import { iconExists } from 'iconify-icon';

interface AppIconArgs {
  icon: string;
}

export default class AppIcon extends Component<AppIconArgs> {
  get icon() {
    let cffIcon = `cff:${this.args.icon}`;

    if (!iconExists(cffIcon)) {
      warn(`icon ${this.args.icon} not found. please add ${cffIcon} to offline-iconify-icons-loader.ts`, {
        id: 'icon-not-found',
      });

      return this.args.icon;
    }

    return cffIcon;
  }
}
