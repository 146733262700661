import { debug } from '@ember/debug';
import Route from '@ember/routing/route';

export default class DefinitionsRoute extends Route {
  async model() {
    const location = window.location.origin;
    const url = 'crypto.fiscafacile.com';

    // This condition is only here to ensure list of csv-defs are displayed only on Production server. As a user which is OFFLINe shall connect to this server to retrieve the csv-defs
    if (window.location.host === url) {
      debug('On : ' + location);

      let response = await fetch('/definitions/manifest.json');
      let data = await response.json();

      return data;
    } else {
      debug('In route of Definitions : not on ' + url + ', but location : ' + location);

      let data = 'ONLINE';

      return data;
    }
  }
}
