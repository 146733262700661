import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<div class=\"h-100\" {{this.dataModifier @data setup=this.setup update=this.update}}>\n</div>", {"contents":"<div class=\"h-100\" {{this.dataModifier @data setup=this.setup update=this.update}}>\n</div>","moduleName":"cryptofiscafacile-gui/components/ac5/line/index.hbs","parseOptions":{"srcName":"cryptofiscafacile-gui/components/ac5/line/index.hbs"}});
import { action } from '@ember/object';

import { Legend, percent, Tooltip } from '@amcharts/amcharts5';
import { AxisRendererX } from '@amcharts/amcharts5/.internal/charts/xy/axes/AxisRendererX';
import { AxisRendererY } from '@amcharts/amcharts5/.internal/charts/xy/axes/AxisRendererY';
import { GaplessDateAxis } from '@amcharts/amcharts5/.internal/charts/xy/axes/GaplessDateAxis';
import { ValueAxis } from '@amcharts/amcharts5/.internal/charts/xy/axes/ValueAxis';
import { LineSeries } from '@amcharts/amcharts5/.internal/charts/xy/series/LineSeries';
import { XYChart } from '@amcharts/amcharts5/.internal/charts/xy/XYChart';
import { XYCursor } from '@amcharts/amcharts5/.internal/charts/xy/XYCursor';

import { Ac5chart } from '../chart';

import type { AxisRenderer } from '@amcharts/amcharts5/.internal/charts/xy/axes/AxisRenderer';
import type { DateAxis } from '@amcharts/amcharts5/.internal/charts/xy/axes/DateAxis';
import type { ColumnSeries } from '@amcharts/amcharts5/.internal/charts/xy/series/ColumnSeries';

interface Ac5LineArgs {
  dateField: string;
  data: { [x: string]: number }[];
  valueFields: string[];
}

export default class Ac5Line extends Ac5chart<Ac5LineArgs> {
  chart?: XYChart;
  legend?: Legend;
  series?: ColumnSeries;
  xAxis?: DateAxis<AxisRenderer>;
  yAxis?: ValueAxis<AxisRenderer>;

  @action
  setup(element: HTMLElement) {
    super.setupChart(element);
  }

  protected configure() {
    if (!this.root) {
      return;
    }

    this.chart = this.root.container.children.push(
      XYChart.new(this.root, {
        layout: this.root.verticalLayout,
      }),
    );

    this.legend = this.chart.children.push(
      Legend.new(this.root, {
        x: percent(5),
      }),
    );

    this.legend.labels.template.setAll({
      fontSize: 12,
      paddingRight: 0,
    });

    const cursor = this.chart.set(
      'cursor',
      XYCursor.new(this.root, {
        xAxis: this.xAxis,
      }),
    );

    cursor.lineY.set('visible', false);

    this.xAxis = this.chart.xAxes.push(
      GaplessDateAxis.new(this.root, {
        baseInterval: { timeUnit: 'day', count: 1 },
        groupData: false,
        renderer: AxisRendererX.new(this.root, {}),
        tooltip: Tooltip.new(this.root, {}),
        tooltipDateFormat: 'dd/MM/yyyy',
      }),
    );

    this.yAxis = this.chart.yAxes.push(
      ValueAxis.new(this.root, {
        renderer: AxisRendererY.new(this.root, {}),
      }),
    );

    this.update();
  }

  #createSeries(name: string, field: string) {
    if (!this.root || !this.chart || !this.xAxis || !this.yAxis) {
      return;
    }

    const series = this.chart.series.push(
      LineSeries.new(this.root, {
        name: name,
        xAxis: this.xAxis,
        yAxis: this.yAxis,
        stacked: true,
        valueYField: field,
        valueXField: this.args.dateField,
        tooltip: Tooltip.new(this.root, {
          pointerOrientation: 'left',
          labelText: '[fontSize: 16px bold]{name}[/][fontSize: 14px]{dateX}: {valueY} €[/]',
          paddingTop: 3,
          paddingBottom: 3,
        }),
      }),
    );

    series.fills.template.setAll({
      fillOpacity: 0.5,
      visible: true,
    });

    series.data.setAll(this.args.data);
    series.appear(1000);
  }

  @action
  update(): void {
    this.chart?.series.clear();
    this.xAxis?.data.setAll(this.args.data);
    this.args.valueFields.forEach((field) => this.#createSeries(field, field));

    if (this.chart?.series?.values) {
      this.legend?.data.setAll(this.chart.series.values);
    }

    this.chart?.appear(1000, 100);
  }
}
