import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<BsButton {{on \"click\" this.download}} class=\"btn btn-primary btn-sm\">\n  Télécharger\n</BsButton>", {"contents":"<BsButton {{on \"click\" this.download}} class=\"btn btn-primary btn-sm\">\n  Télécharger\n</BsButton>","moduleName":"cryptofiscafacile-gui/components/download/index.hbs","parseOptions":{"srcName":"cryptofiscafacile-gui/components/download/index.hbs"}});
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { debug } from '@ember/debug';
import { action } from '@ember/object';

interface DownloadArgs {
  def: string;
}

export default class Download extends Component<DownloadArgs> {
  @tracked filename = '';
  @tracked fileURL = '';

  @action
  async download() {
    debug('Downloading...' + this.args.def + '...');

    let response = await fetch('/definitions/' + this.args.def);
    let data = await response.json();

    this.#downloadObjectAsJson(data, this.args.def);
  }

  #downloadObjectAsJson(exportObj: Response, exportName: string) {
    let dataStr = 'data:text/json;charset=utf-8,' + encodeURIComponent(JSON.stringify(exportObj));
    let downloadAnchorNode = document.createElement('a');

    downloadAnchorNode.setAttribute('href', dataStr);
    downloadAnchorNode.setAttribute('download', exportName);
    document.body.appendChild(downloadAnchorNode); // required for firefox
    downloadAnchorNode.click();
    downloadAnchorNode.remove();
  }
}
