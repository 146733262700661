import EmberRouter from '@ember/routing/router';

import config from 'cryptofiscafacile-gui/config/environment';

export default class Router extends EmberRouter {
  location = config.locationType;
  rootURL = config.rootURL;
}

Router.map(function () {
  this.route('document', { path: 'documentation' });
  this.route('wallets');
  this.route('not-found', { path: '/*/' });
  this.route('transactions');
  this.route('tools');
  this.route('reports');
  this.route('definitions');
});
